import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {finalize, Observable, Subject, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environment/environment';
import {ErrorHandlerService} from "../services/error-handler.service";


@Injectable({
  providedIn: 'root',
})
export class ApiClient {
  API_URL: string = environment.API_URL;
  private isLoading = false;
  private pendingRequests = new Map<string, Subject<any>>();
  constructor(private http: HttpClient, private errorHandler: ErrorHandlerService) {}

  private createHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.getAuthToken()}`,
    });
  }

  private getAuthToken(): string | null {
    return localStorage.getItem('jwtToken');
  }

  getAll<T>(endpoint: string): Observable<T> {
    if (!this.pendingRequests.has(endpoint)) {
      const subject = new Subject<T>();
      this.pendingRequests.set(endpoint, subject);

      this.http.get<T>(`${this.API_URL}/${endpoint}`, { headers: this.createHeaders() }).pipe(
        catchError(this.errorHandler.handleError),
        finalize(() => this.pendingRequests.delete(endpoint))
      ).subscribe(subject);

      return subject.asObservable();
    }
    return this.pendingRequests.get(endpoint) as Observable<T>;
  }

  get<T>(endpoint: string, id: string | null): Observable<T> {
    return this.http.get<T>(`${this.API_URL}/${endpoint}/${id}`, { headers: this.createHeaders() }).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  post<T>(endpoint: string, data: T): Observable<T> {
    return this.http.post<T>(`${this.API_URL}/${endpoint}`, data, { headers: this.createHeaders() }).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  put<T>(endpoint: string, id: string | null, data: T): Observable<T> {
    return this.http.put<T>(`${this.API_URL}/${endpoint}/${id}`, data, { headers: this.createHeaders() }).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  putwithoutId<T>(endpoint: string, data: T): Observable<T> {
    return this.http.put<T>(`${this.API_URL}/${endpoint}`, data, { headers: this.createHeaders() }).pipe(
      catchError(this.errorHandler.handleError)
    );
  }


  valid<T>(endpoint: string, id: string | null, path: string): Observable<T> {
    return this.http.put<T>(`${this.API_URL}/${endpoint}/${id}/${path}`, {}, { headers: this.createHeaders() }).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  putWithOnlyId<T>(endpoint: string, id: string | null): Observable<T> {
    return this.http.put<T>(`${this.API_URL}/${endpoint}/${id}`, {}, { headers: this.createHeaders() }).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  bulk<T>(endpoint:string, employees: any[]): Observable<T> {
    return this.http.post<T>(`${this.API_URL}/${endpoint}`, employees, { headers: this.createHeaders() }).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  delete<T>(endpoint: string, id: string): Observable<T> {
    if (this.isLoading) {
      return throwError(() => new Error('Requête déjà en cours'));
    }
    return this.http.delete<T>(`${this.API_URL}/${endpoint}/${id}`, { headers: this.createHeaders() }).pipe(
      finalize(() => this.isLoading = false),
      catchError(this.errorHandler.handleError)
    );
  }
}
