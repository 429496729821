export class FilterApiModel {
  private _query: string = "";
  private _page: number = 0;
  private _size: number = 10;
  private _sorted?: string;
  private _sortedDirection?: string;
  private andSeparator = ";";
  private orSeparator = ",";

  // Getters
  get page(): number {
    return this._page;
  }

  get size(): number {
    return this._size;
  }

  get sorted(): string | undefined {
    return this._sorted;
  }

  get sortedDirection(): string | undefined {
    return this._sortedDirection;
  }

  get query(): string {
    return this._query;
  }

  // Setters
  set query(value: string) {
    this._query = value;
  }

  set page(value: number) {
    this._page = value;
  }

  set size(value: number) {
    this._size = value;
  }

  set sorted(value: string | undefined) {
    this._sorted = value;
  }

  set sortedDirection(value: string | undefined) {
    this._sortedDirection = value;
  }

  constructor(page: number, size: number, query: string, sorted?: string, sortedDirection?: string) {
    this._page = page;
    this._size = size;
    this._query = query;
    this._sorted = sorted;
    this._sortedDirection = sortedDirection;
  }

  /**
   * Ajoute un filtre avec l'opérateur AND.
   */
  public filterAnd(
    key: string,
    valueFilter: any,
    ignoreCase: boolean = false,
    operator: string = "=",
    isDateRange: boolean = false,
    startDate?: string,
    endDate?: string
  ): void {
    if (isDateRange && startDate && endDate) {
      // Filtre par plage de dates
      const filterQuery = `${key}=gt='${startDate}'${this.andSeparator}${key}=lt='${endDate}'`;
      this._query = this._query ? `${this._query}${this.andSeparator}${filterQuery}` : filterQuery;
    } else if (!isDateRange && valueFilter !== null && valueFilter !== undefined) {
      // Filtre standard
      this.addFilter(key, valueFilter, ignoreCase, operator, this.andSeparator);
    }
  }

  /**
   * Ajoute un filtre avec l'opérateur OR.
   */
  public filterOr(key: string, valueFilter: any, ignoreCase: boolean = false, operator: string = "="): void {
    this.addFilter(key, valueFilter, ignoreCase, operator, this.orSeparator);
  }

  /**
   * Réinitialise uniquement la pagination sans toucher aux filtres.
   */
  public resetPagination(): void {
    this._page = 0; // Réinitialise à la première page
    this._size = 10; // Taille par défaut
  }

  /**
   * Génère la requête RSQL.
   */
  public getRsqlQuery(): string {
    const queryParams: string[] = [];

    if (this._query.trim() !== "") {
      queryParams.push(`query=${encodeURIComponent(this._query.trim())}`);
    }

    if (this._page != null) {
      queryParams.push(`page=${this._page}`);
    }

    if (this._size !== null) {
      queryParams.push(`size=${this._size}`);
    }

    if (this._sorted) {
      queryParams.push(`sorted=${encodeURIComponent(this._sorted)}`);
    }

    if (this._sortedDirection) {
      queryParams.push(`sortedDirection=${encodeURIComponent(this._sortedDirection)}`);
    }

    return queryParams.join("&");
  }

  /**
   * Ajoute un filtre à la requête.
   */
  private addFilter(
    key: string,
    valueFilter: any,
    ignoreCase: boolean,
    operator: string,
    separatorOperator: string
  ): void {
    const filterValue = ignoreCase ? valueFilter.toLowerCase() : valueFilter;
    const filterQuery = `${key}${operator}=${filterValue}`;

    if (!this._query) {
      this._query = filterQuery;
    } else {
      this._query += `${separatorOperator}${filterQuery}`;
    }
  }
}
